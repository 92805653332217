import React from "react"
import { Faq } from "../../components/faq"

const breadcrumbsData: {text: string; path:string}[] = [
  {text: `FAQ`, path: `question`},
]

export default function FaqPage() {
  return (
    <Faq
      breadcrumbsData={breadcrumbsData}
    />
  )
}