import React from "react"
import styled from "styled-components"
import Layout from "../../template/Layout"
import Head from "../../head";

/**
 * Material
 */
import Breadcrumbs, {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"
import { RESPONSIVE_STYLES, TABLET_STYLES } from "../../AppConst";
import { SectionTitle } from '../common/SectionTitle'
import { ButtonArea } from '../faq/ButtonArea'

/**
 * Element & Styles
 */
const Node = styled.div`
  max-width: 960px;
  margin: auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`
const Main = styled.div`
  width: 100%;
  @media (max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px) {
    max-width: 440px;
    margin: auto;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 335px;
    margin: auto;
  }
`
const Message = styled.p`
  width: 100%;
  text-align: center;
  margin: 160px 0;
  font-weight: 600;
  font-family: 'Hiragino Sans';
  font-size: 2rem;
  letter-spacing: 2px;
  line-height: 40px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px) {
    margin: 80px 0;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.4rem;
    letter-spacing: 1.4px;
  }
`

const CategoryTitle = styled.div`
  width: 100%;
  line-height: 1;
  margin-top: 96px;
  text-align: left;
  font-weight: 400;
  font-family: 'Righteous';
  font-size: 3.2rem;
  letter-spacing: 3.2px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px) {
    margin-top: 48px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 2.4rem;
    letter-spacing: 2.4px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 24px;
`

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"];
}

/**
 * Component
 */
export const Faq: React.VFC<Props> = (props) => {
  return(
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="よくある質問 | ネクストライブ株式会社"
        description="ネクストライブ株式会社の事業や採用に関するよくある質問と回答を掲載しています。"
      />
      <Node>
        <SectionTitle title='FAQ' subTitle='よくある質問' color="#222222" />
        <Main>
          <CategoryTitle>SERVICE</CategoryTitle>
          <ButtonWrapper>
            <ButtonArea
              buttonText1={`システム開発・アプリ開発`}
              url1={`/question/development`}
              buttonText2={`Web制作`}
              url2={`/question/web`}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonArea
              buttonText1={`システム開発支援サービス`}
              url1={`/question/support`}
              buttonText2={`エンジニア育成 0→1 ENGINEER CAMP`}
              url2={`/question/01engineer-camp`}
            />
          </ButtonWrapper>
          <CategoryTitle>RECRUIT</CategoryTitle>
          <ButtonWrapper>
            <ButtonArea
              buttonText1={`採用について`}
              url1={`/question/recruit`}
            />
          </ButtonWrapper>
          <CategoryTitle>OTHER</CategoryTitle>
          <ButtonWrapper>
            <ButtonArea
              buttonText1={`パートナーシップについて`}
              url1={`/question/partnership`}
            />
          </ButtonWrapper>
        </Main>
        <Message>
          当ページにご質問が見当たらない場合や、その他のご質問がある場合は、<br />以下のフォームよりお問い合わせください。
        </Message>
      </Node>
    </Layout>
  )
}