import React from "react"
import styled from "styled-components"

/**
 * Material
 */
import { RESPONSIVE_STYLES, TABLET_STYLES } from "../../AppConst";
import { RoundLinkButton } from "../common/RoundLinkButton";

/**
 * Element & Styles
 */
const Node = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  text-align: center;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    row-gap: 16px;
  }
`

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 80px;
`
const ButtonWrapper = styled.div`
  width: 440px;
  height: 96px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 335px;
    height: 80px;
  }
`
/**
 * Props
 */
type Props = {
  buttonText1: string
  url1: string
  buttonText2?: string
  url2?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

/**
 * Component
 */
export const ButtonArea: React.VFC<Props> = (props) => {
  return(
    <Node>      
      <ButtonsWrapper>
        <ButtonWrapper>
          <RoundLinkButton title={props.buttonText1} url={props.url1} />
        </ButtonWrapper>
        { props.buttonText2 != null &&
          <ButtonWrapper>
            <RoundLinkButton title={props.buttonText2} url={props.url2} />
          </ButtonWrapper>
        }
      </ButtonsWrapper>
    </Node>
  )
}